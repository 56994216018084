import React from 'react';
import './styles.module.css';
import { navigate } from '@reach/router';

/**
 * Header component
 */
export default function Header() {
  return (
    <div styleName="wrapper">
      <div className="container">
        <h1
          onClick={() => {
            navigate('/');
          }}
          styleName="header_logo"
        >
          Verify-Resume<span>.org</span>
        </h1>
      </div>
    </div>
  );
}
