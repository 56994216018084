import React, { useEffect, useState } from 'react';
import './styles.module.css';
import Modal from '../../components/ImgModal';
import Loading from '../Loading';

export default function Result({ checkedFile, result, onLoad }) {
  const [step, setStep] = useState(0);
  const [imgModal, setImgModal] = useState(false);

  useEffect(() => {
    result && checkAnimation();
    result && console.log(result.image);
  }, [result]);

  const checkAnimation = () => {
    setTimeout(() => {
      setStep(1);
      setTimeout(() => {
        setStep(2);
        setTimeout(() => {
          setStep(3);
          if (result)
            setTimeout(() => {
              setStep(4);
              onLoad();
            }, 500);
        }, 500);
      }, 500);
    }, 500);
  };

  return (
    <div styleName="result">
      <div
        styleName="search"
        className={step !== 4 ? 'loading' : 'loading-done'}
      >
        <ul>
          <li>
            {step < 1 ? (
              <Loading
                stylename="small"
                minHeight="10"
                style={{ top: '4px', left: '-30px' }}
              />
            ) : (
              <i
                className="fad fa-check"
                style={{
                  color: 'var(--color-green)',
                  position: 'absolute',
                  left: '-28px'
                }}
              />
            )}
            Getting information from Network
          </li>
          <li>
            {step < 2 ? (
              <Loading
                stylename="small"
                minHeight="10"
                style={{ top: '4px', left: '-30px' }}
              />
            ) : (
              <i
                className="fad fa-check"
                style={{
                  color: 'var(--color-green)',
                  position: 'absolute',
                  left: '-28px'
                }}
              />
            )}
            Extracting data from CID
          </li>
          <li>
            {step < 3 ? (
              <Loading
                stylename="small"
                minHeight="10"
                style={{ top: '4px', left: '-30px' }}
              />
            ) : (
              <i
                className="fad fa-check"
                style={{
                  color: 'var(--color-green)',
                  position: 'absolute',
                  left: '-28px'
                }}
              />
            )}
            Extracting Image from IPFS server
          </li>
          <li>
            {step < 4 ? (
              <Loading
                stylename="small"
                minHeight="10"
                style={{ top: '4px', left: '-30px' }}
              />
            ) : (
              <i
                className="fad fa-check"
                style={{
                  color: 'var(--color-green)',
                  position: 'absolute',
                  left: '-28px'
                }}
              />
            )}
            Verification of Authenticity
          </li>
        </ul>
      </div>
      {step === 4 && (
        <>
          {checkedFile && checkedFile.fileName && (
            <>
              <div styleName="div-block info info-2-line">
                <h2 className="heading-2 black" styleName="bold">
                  File
                </h2>
                <h2 className="heading-2">{checkedFile.fileName}</h2>
              </div>
              <div className="spacer" />
            </>
          )}
          {checkedFile && checkedFile.sha && (
            <>
              <div styleName="div-block info info-2-line">
                <h2 className="heading-2 black" styleName="bold">
                  Resume hash from file
                </h2>
                <h2 className="heading-2" styleName="hash-text">
                  {checkedFile.sha}
                </h2>
              </div>
              <div className="spacer" />
            </>
          )}
          {checkedFile && result.pdf && checkedFile.sha !== result.pdf && (
            <>
              <div styleName="div-block">
                <i className="fad fa-times-circle" style={{ color: 'red' }} />
                <h2>The certification doesn't correspond to this file</h2>
              </div>
              <div className="spacer" />
            </>
          )}

          {checkedFile && result.pdf && checkedFile.sha !== result.pdf && (
            <>
              <div styleName="div-block">
                <i className="fad fa-check-circle" />
                <h2>The owner of this resume has been verified</h2>
              </div>
              <div className="spacer" />
              <div styleName="div-block">
                <i className="fad fa-check-circle" />
                <h2>The owner has certified resume experience is truthful</h2>
              </div>
              <div className="spacer" />
            </>
          )}
          {result && (
            <>
              <div styleName="div-block">
                <i className="fad fa-check-circle" />
                <h2>This issuer is verified by Rezi</h2>
              </div>
              <div className="spacer" />
              <div styleName="div-block info info-2-line">
                <h2 className="heading-2 black" styleName="bold">
                  Transaction Address
                </h2>
                <a href={result.link} target="_blank">
                  <h2 className="heading-2" styleName="hash-text">
                    {result.address}
                  </h2>
                </a>
              </div>
              <div className="spacer" />
              <div styleName="div-block info info-2-line">
                <h2 className="heading-2 black" styleName="bold">
                  Resume hash from Blockchain
                </h2>
                <h2 className="heading-2" styleName="hash-text">
                  {result.pdf}
                </h2>
              </div>
              <div className="spacer" />
              <div styleName="div-block info">
                <h2 className="heading-2 black" styleName="bold">
                  Network
                </h2>
                <h2 className="heading-2">{result.network}</h2>
              </div>
              <div className="spacer" />

              <div styleName="img-block">
                <img
                  src={result.image}
                  onClick={() => {
                    setImgModal(true);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {imgModal && (
        <Modal
          open={imgModal}
          onClose={() => {
            setImgModal(false);
          }}
        >
          <img src={result.image} styleName="modal_img" />
        </Modal>
      )}
    </div>
  );
}
