import React from 'react';

import ContentsWarp from '../ContentsWarp';
import Hero from '../Hero';
import './styles.module.css';

/**
 * Result component
 */
export default function Rending() {
  return (
    <div styleName="reading_wrapper">
      <Hero iconDirection="column">
        <h1 styleName="header_text">
          Welcome to
          <br /> verify-resume.org!
        </h1>
      </Hero>
      <div className="container" style={{ display: 'block' }}>
        <ContentsWarp>
          <div styleName="text_box">
            <p>
              Verify-Resume.org is a resource with a mission to authenticate,
              standardize, and certify resumes across the web. We provide a
              platform for job seekers to create their resumes and for employers
              to verify the resumes of potential employees.
              <strong />
              Our platform is built on the pillars of authentication and
              standardization. We authenticate resumes by verifying the identity
              of the author and the content of the resume. We standardize
              resumes by formatting them according to a common template.
              <strong />
              We believe that a verified resume is a key to success in the job
              market. By providing a platform for verification, we aim to create
              a more efficient and fair job market for everyone involved.
              <strong />
            </p>
          </div>
          <p>V1.0</p>
        </ContentsWarp>
      </div>
    </div>
  );
}
