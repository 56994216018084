import React, { useEffect, useState } from 'react';
const pkg = require('../../package.json');

export function setWithExpiry(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export default function CacheBuster({ children }) {
  const [state, setState] = useState({
    loading: true,
    isLatestVersion: false,
    latestVersion: '',
    currentVersion: ''
  });

  useEffect(() => {
    checkMeta();
    const interval = setInterval(() => {
      checkMeta(true);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const checkMeta = (interval = false) => {
    if (process.env.GENERAL_ENV === 'production') {
      window
        .fetch('/vrzn.json', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
        .then(response => response.json())
        .then(meta => {
          const latestVersion = meta.version;
          const currentVersion = sessionStorage.getItem('rz-vrzn');
          if (!currentVersion) {
            sessionStorage.setItem('rz-vrzn', latestVersion);
            setState({
              ...state,
              currentVersion: latestVersion
            });
            refreshCacheAndReload();
          } else {
            if (latestVersion !== currentVersion) {
              setState({
                loading: false,
                isLatestVersion: false,
                latestVersion: latestVersion,
                currentVersion: currentVersion
              });
              if (!interval) {
                sessionStorage.setItem('rz-vrzn', latestVersion);
                refreshCacheAndReload();
              }
            } else {
              setState({
                loading: false,
                isLatestVersion: true,
                latestVersion: latestVersion,
                currentVersion: currentVersion
              });
            }
            sessionStorage.setItem('rz-vrzn', latestVersion);
          }
        })
        .catch(() => {
          setState({ loading: false, isLatestVersion: true });
        });
    }
  };

  return (
    <>
      {children(
        state.loading,
        state.isLatestVersion,
        state.latestVersion,
        pkg.version
      )}
    </>
  );
}
