import React, { Fragment } from 'react';
import './styles.module.css';
import { ReactComponent as Fingerprint } from '../../assets/fingerprint-light.svg';

export default function Hero({
  title,
  text,
  verified,
  iconDirection,
  children
}) {
  return (
    <div styleName="wrapper">
      <div className="container">
        <div styleName={iconDirection ? iconDirection : 'row'}>
          <Fingerprint
            styleName={verified && (verified === 'success' ? 'green' : 'red')}
          />
          <div styleName="content">
            {children ? (
              <div>{children}</div>
            ) : (
              <Fragment>
                <h1>{title}</h1>
                <p>{text}</p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
