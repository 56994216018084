import React from 'react';
import isOnline from 'is-online';
import {
  setWithExpiry,
  getWithExpiry
} from '../../utils/compareSemanticVersion';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (
      error.name === 'ChunkLoadError' &&
      chunkFailedMessage.test(error.message)
    ) {
      return { hasChunkError: true };
    }
    return { hasCriticalError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.state.hasChunkError) {
      // This is a chunk error, check if this error is normal or critical
      if (caches) {
        // Browser support caches, we can check chunk existence
        caches.match(error.request).then(r => {
          isOnline().then(connected => {
            if (connected && !getWithExpiry('chunk_failed')) {
              setWithExpiry('chunk_failed', 'true', 10000);

              // user is online so missing chunk come from new version
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
              // delete browser cache and hard reload
              window.location.reload(true);
            } else {
              // user is disconnected, missing chunk come from offline
              this.setState({ isOffline: true });
            }
          });
        });
      } else {
        // if caches is not defined (not supported browser), then error is critical but we do not need to log to sentry
        this.setState({ hasCriticalError: true });
      }
    }
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
