import React, { useState, useRef, useEffect } from 'react';
import './styles.module.css';
import { notify } from '../Notification';

const CryptoJS = require('crypto-js');

/**
 * Upload component
 */
export default function Upload({ checked }) {
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (file !== null) checked(file);
  }, [file]);

  function hash(string) {
    return CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
  }

  function allowDrop(ev) {
    ev.preventDefault();
  }

  async function drop(ev) {
    ev.preventDefault();
    setFile(null);
    setDrag(false);

    if (ev.dataTransfer.items) {
      if (
        ev.dataTransfer.items[0].kind === 'file' &&
        ev.dataTransfer.items[0].type.match('^application/pdf')
      ) {
        const file = ev.dataTransfer.items[0].getAsFile();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
          const base64 = reader.result.replace(
            'data:application/pdf;base64,',
            ''
          );
          const hashedSha = hash(base64);
          setFile({
            sha: hashedSha,
            fileName: file.name
          });
        };
      } else {
        notify();
      }
    } else {
      console.log('here');
    }
  }

  useEffect(() => {
    const dropZone = document.getElementById('drop-zone');
    dropZone.addEventListener(
      'dragleave',
      function(e) {
        setDrag(false);
      },
      false
    );
    dropZone.addEventListener(
      'dragover',
      function(e) {
        e.preventDefault();
        setDrag(true);
      },
      false
    );
    const inputElement = document.getElementById('inputField');
    inputElement.addEventListener('change', handleFiles, false);
  }, []);

  function handleFiles() {
    setFile(null);
    var fileList = this.files;
    const file = fileList[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const base64 = reader.result.replace('data:application/pdf;base64,', '');
      const hashedSha = hash(base64);
      setFile({
        sha: hashedSha,
        fileName: file.name
      });
    };
  }
  return (
    <div styleName="upload">
      <div
        styleName={drag ? 'drag' : ''}
        onDrop={e => drop(e)}
        id="drop-zone"
        onDragOver={e => allowDrop(e)}
      >
        <label className={file ? 'small-upload' : ''}>
          <span>Upload PDF Resume to Verify Authenticity</span>
          <input id="inputField" type="file" hidden />
        </label>
      </div>
    </div>
  );
}
