import React, { useEffect, useState } from 'react';
import Hero from '../../components/Hero';
import Upload from '../../components/Upload';
import Result from '../../components/Result';
import ContentsWarp from '../../components/ContentsWarp';
import { navigate } from '@reach/router';
import './styles.module.css';

export default function VerifyPage() {
  const [verified, setVerified] = useState(null);
  const [title, setTitle] = useState('Verify Resume Authenticity');
  const [text, setText] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkedFile, setCheckedFile] = useState(false);

  const checkResult = async address => {
    const headers = new Headers();
    headers.append('Content-Type', `application/json`);
    window
      .fetch(`${process.env.PROD_API}/checkBvrTransaction?id=` + address, {
        method: 'POST',
        headers: headers
      })
      .then(res => {
        return res.json();
      })
      .then(async res => {
        if (res && res.message === 'success' && res.jsonFile) {
          const { name, image, pdf, address, network, scanUrl } = res.jsonFile;
          setResult({
            name,
            network,
            image:
              image.length > 100 ? `data:image/png;base64,${image}` : image,
            pdf,
            address,
            link: `${scanUrl}${address}`
          });
        } else {
          navigate('/');
        }
      })
      .catch(err => {
        navigate('/');
      });
  };

  useEffect(() => {
    const url = window.location.href.split('/verify/');
    const docId = url[1];
    console.log(docId);
    if (docId) {
      checkResult(docId);
    } else {
      navigate('/');
    }
  }, []);

  const checkVerified = () => {
    if (checkedFile.sha === result.pdf) {
      setVerified('success');
      setTitle('Resume is confirmed as authentic original');
      setText(
        'This digital credential was securely issued via Rezi Fingerprint and its information is valid.'
      );
    } else if (checkedFile.sha !== result.pdf) {
      setVerified('fail');
      setTitle('Resume authenticity cannot be verified');
      setText('The uploaded PDF resume does not correspond to this file');
    }
  };

  useEffect(() => {
    !loading && checkedFile && checkVerified();
  }, [checkedFile, loading]);

  const finishLoading = () => {
    setVerified('success');
    setText(
      'This digital credential was securely issued via Rezi Fingerprint and its information is valid.'
    );
    setLoading(false);
  };

  return (
    <div>
      <Hero title={title} text={text} verified={verified} />
      <div className="container">
        <ContentsWarp>
          <div styleName="verification-card">
            <Upload checked={data => setCheckedFile(data)} loading={result} />
            <Result
              checkedFile={checkedFile}
              result={result}
              onLoad={finishLoading}
            />
          </div>
        </ContentsWarp>
      </div>
    </div>
  );
}
