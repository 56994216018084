import polyfill from 'dynamic-polyfill';
import { observer } from 'mobx-react-lite';
import React, { useRef, useEffect, Suspense } from 'react';
import ErrorBoundary from '../../hoc/ErrorBoundary';
import CacheBuster from '../../utils/compareSemanticVersion';
import { Router } from '@reach/router';
import './css/index.css';
import styles from './styles.module.css';
import 'whatwg-fetch';
import { HelmetProvider } from 'react-helmet-async';
import Header from '../../components/Header';
import VerifyPage from '../VerifyPage';
import Rending from '../../components/Rending';
import { navigate } from '@reach/router';

// 'Use' styles to stop linters removing it
styles;

const POLYFILLS = ['Array.from'];

function dynamicPolyfill() {
  const cssVars = {
    test:
      ((window || {}).CSS || {}).supports && window.CSS.supports('(--a: 0)'),
    config: {
      watch: true,
      updateURLs: false,
      onComplete() {
        setTimeout(() => (document.body.style.visibility = 'visible'), 10);
      }
    }
  };

  POLYFILLS.length &&
    polyfill({
      fills: POLYFILLS,
      rum: false,
      afterFill() {
        return null;
      }
    });

  // Custom polyfill for CSS Variables
  if (!cssVars.test) {
    import(
      /* webpackChunkName: "css-vars-ponyfill" */ 'css-vars-ponyfill'
    ).then(({ default: cssVarsPonyfill }) => {
      document.body.style.visibility = 'hidden';
      cssVarsPonyfill(cssVars.config);
    });
  }
}

/**
 * App container
 * Entrypoint for the application
 */
const App = observer(() => {
  const anchorForScript = useRef(null);

  const useKit = () => {
    let d = document;
    var config = {
        kitId: 'xtv1isi',
        scriptTimeout: 3000,
        async: true
      },
      h = d.documentElement,
      t = setTimeout(function() {
        h.className =
          h.className.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
      }, config.scriptTimeout),
      tk = d.createElement('script'),
      f = false,
      s = d.getElementsByTagName('script')[0],
      a;
    h.className += ' wf-loading';
    tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
    tk.async = true;
    tk.onload = tk.onreadystatechange = function() {
      a = this.readyState;
      if (f || (a && a != 'complete' && a != 'loaded')) return;
      f = true;
      clearTimeout(t);
      try {
        Typekit.load(config);
      } catch (e) {}
    };
    s.parentNode.insertBefore(tk, s);
  };

  const initView = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://kit.fontawesome.com/119560e0cc.js';
    anchorForScript.current.appendChild(script);
    useKit();
    dynamicPolyfill();
  };

  const init = () => {
    initView();
  };

  useEffect(() => {
    init();
    {
      window.location.href.indexOf('/verify/') === -1 && navigate('/');
    }
  }, []);

  return (
    <ErrorBoundary>
      <CacheBuster>
        {(loading, isLatestVersion, currentVersion, version) => {
          return (
            <Suspense fallback={<></>}>
              <HelmetProvider>
                <div
                  styleName={
                    !loading && !isLatestVersion
                      ? 'styles.updateTopBar  styles.active'
                      : 'styles.updateTopBar'
                  }
                >
                  We just made fix and/or add features! Please reload the page
                  to get the latest version.
                </div>
                <div ref={anchorForScript} />
                <Header />
                <Router primary={false}>
                  <Rending path="/" />
                  <VerifyPage path="/verify/*" />
                </Router>
                <div styleName="styles.versionApp">
                  {currentVersion
                    ? `version: ${version}-${currentVersion.split('-')[0]}`
                    : version
                    ? `version: ${version}`
                    : ''}
                </div>
              </HelmetProvider>
            </Suspense>
          );
        }}
      </CacheBuster>
    </ErrorBoundary>
  );
});

export default App;
