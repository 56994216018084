import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import './styles.module.css';
import { ReactComponent as CloseIcon } from '../../assets/icons/clear.svg';

/**
 * Modal component
 * Modal UI component
 * @property {boolean} open Whether modal is open
 * @property {function} onClose Callback when modal requests closing
 */
export default function Modal({
  open = false,
  onClose = () => null,
  children,
  className,
  customOverlayClassName = '',
  clickAway = true,
  modalId,
  ...attrs
}) {
  return (
    <ReactModal
      styleName="modal"
      overlayClassName={{
        base: `modal-overlay`,
        afterOpen: 'open',
        beforeClose: 'closing'
      }}
      isOpen={open}
      id={modalId}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={clickAway}
      closeTimeoutMS={500}
      ariaHideApp={false}
      className={className || ''}
      {...attrs}
    >
      <CloseIcon styleName="modal-close" onClick={onClose} />
      {children}
    </ReactModal>
  );
}
